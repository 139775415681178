import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

// Import menuDropdown
import ProfileMenu from '../CommonForBoth/TopbarDropdown/ProfileMenu';
import NotificationDropdown from '../CommonForBoth/TopbarDropdown/NotificationDropdown';

//i18n
import { withTranslation } from 'react-i18next';

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from '../../store/actions';
import { apiUrl } from '../../utils/constants';
import ApiHandling from '../../utils/ApiHandling';

const Header = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem('authUser'));
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [builderDetails, setBuilderDetails] = useState({
    registeredName: '',
    displayName: '',
    builderLogo: '',
  });

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === 'default') {
      props.changeSidebarType('condensed', isMobile);
    } else if (props.leftSideBarType === 'condensed') {
      props.changeSidebarType('default', isMobile);
    }
  }

  useEffect(() => {
    if (decodedToken && decodedToken.userType === 'builder') {
      const id = decodedToken.user;
      ApiHandling.fetchApi(
        `${apiUrl}/${decodedToken.userType}?id=${decodedToken.foreKey}`
      ).then((response) => {
        if (response?.status === 200) {
          setBuilderDetails(response.data[0]);
        }
      });
    }
  }, []);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link
                to={`/${decodedToken.userType}/${
                  decodedToken.userType !== 'agent' ? 'users' : 'dashboard'
                }`}
                className="logo logo-light"
              >
                <span
                  className="logo-sm"
                  style={{
                    color: '#005fad',
                    fontSize: '24px',
                    fontWeight: '500',
                  }}
                ></span>
                {builderDetails.builderLogo ? (
                  <img
                    data-dz-thumbnail=""
                    className="bg-light"
                    alt="profile-pic"
                    src={builderDetails.builderLogo}
                    style={{
                      width: 'auto',
                      maxHeight: '70px',
                      maxWidth: '240px',
                    }}
                  />
                ) : (
                  <span
                    className="logo-lg"
                    style={{
                      color: '#005fad',
                      fontSize: '24px',
                      fontWeight: '500',
                    }}
                  >
                    Realtor.works
                  </span>
                )}
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>
          </div>
          <div className="d-flex">
            <div className="dropdown d-none d-lg-inline-block ml-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen"></i>
              </button>
            </div>
            <NotificationDropdown />
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
