import { takeEvery, fork, put, all } from 'redux-saga/effects';

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from './actionTypes';
import { loginSuccess, logoutUserSuccess, apiError } from './actions';
import ApiHandling from '../../../utils/ApiHandling';
import jwt_decode from 'jwt-decode';
import {
  getCountries,
  getUserRoles,
  getIncativeLeads,
} from '../../miscellaneous/actions';

function* loginUser({ payload: { user, history } }) {
  try {
    let { data } = yield ApiHandling.fetchApi(
      `${process.env.REACT_APP_API_URL}/global/auth`,
      { userDetails: user }
    );
    localStorage.setItem('accessToken', data);
    const response = jwt_decode(data);
    localStorage.setItem('authUser', JSON.stringify(response));

    yield put(loginSuccess(response));
    yield put(getUserRoles());
    yield put(getCountries());
    yield put(getIncativeLeads());

    switch (response.userType.toLowerCase()) {
      case 'bbuser':
        history.push('/bbUser/users');
        break;
      case 'agent':
        history.push('/agent/dashboard');
        break;
      case 'builder':
        history.push('/builder/dashboard');
        break;
      case 'marketingagency':
        history.push('/marketingAgency/dashboard');
        break;
      default:
        history.push('/');
    }
  } catch (error) {
    put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    const role = JSON.parse(localStorage.getItem('authUser')).userType;
    localStorage.removeItem('authUser');
    localStorage.removeItem('accessToken');
    yield put(logoutUserSuccess(true));
    switch (role) {
      case 'bbUser':
        history.push('/login');
        break;
      default:
        history.push('/login');
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

export function* watchUserLogin() {
  yield takeEvery(LOGIN_USER, loginUser);
}

export function* watchUserLogout() {
  yield takeEvery(LOGOUT_USER, logoutUser);
}

function* authSaga() {
  yield all([fork(watchUserLogin), fork(watchUserLogout)]);
}

export default authSaga;
