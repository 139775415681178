import React, { useState } from 'react';

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  ButtonGroup,
  Button,
} from 'reactstrap';

// Redux
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';

// availity-reactstrap-validation
import { AvForm, AvField } from 'availity-reactstrap-validation';

// actions
import { loginUser, apiError } from '../../store/actions';

// import images
import profile from '../../assets/images/profile-img.png';

const Login = (props) => {
  const [userType, setUserType] = useState('Agent');

  const changeUser = (event) => {
    const currentUserType = event.target.textContent;
    switch (currentUserType.toLowerCase()) {
      case 'builder':
        setUserType(currentUserType);
        break;
      case 'agent':
        setUserType(currentUserType);
        break;
      case 'marketing agency':
        setUserType(currentUserType);
        break;
      default:
    }
  };

  function handleValidSubmit(event, values) {
    props.loginUser(values, props.history);
  }

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="login-container">
            <div className="login-brand">
              <span style={{ color: '#2d2b4f' }}>Realtor</span>.works
            </div>

            <Col md={7} lg={6} xl={5} className="">
              <div style={{ textAlign: 'center', marginBottom: '30px' }}></div>
              <Card className="overflow-hidden">
                <div style={{ backgroundColor: 'rgba(0, 95, 173, 0.33)' }}>
                  <Row>
                    <Col className="col-7">
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back Buddy!</h5>
                        <p>Sign in to continue to Realtor.works.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0 mt-4">
                  <div className="p-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      {props.error && props.error ? (
                        <Alert color="danger">{props.error}</Alert>
                      ) : null}

                      <div className="form-group">
                        <label htmlFor="mobileNumber" required>
                          Mobile Number
                        </label>
                        <AvField
                          name="mobileNumber"
                          className="form-control"
                          placeholder="Enter Mobile Number"
                          type="text"
                          required
                          validate={{
                            pattern: {
                              value: /^\d{10}$/,
                              errorMessage:
                                'Please enter a valid 10-digit mobile number.',
                            },
                          }}
                        />
                      </div>

                      <div className="form-group">
                        <label htmlFor="password" required>
                          Password
                        </label>
                        <AvField
                          name="password"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <div className="mt-5">
                        <button
                          className="btn btn-primary btn-block waves-effect waves-light"
                          type="submit"
                        >
                          Log In
                        </button>
                      </div>

                      <div className="mt-4 text-center">
                        <Link to={`/verifyPhoneNo`} className="text-muted">
                          Verify Phone Number
                        </Link>
                      </div>
                      <div className="mt-4 text-center">
                        <Link to={`/forgot-password`} className="text-muted">
                          <i className="mdi mdi-lock mr-1"></i> Forgot your
                          password?
                        </Link>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                {userType.toLowerCase() === 'agent' ? (
                  <p>
                    Don't have an account ?{' '}
                    <Link
                      to={`/register`}
                      className="font-weight-medium text-primary"
                    >
                      {' '}
                      Signup now{' '}
                    </Link>{' '}
                  </p>
                ) : (
                  <p>
                    Don't have an account ?{' '}
                    <Link
                      to={`/register?userType=${userType}`}
                      className="font-weight-medium text-primary"
                    >
                      {' '}
                      Signup now{' '}
                    </Link>{' '}
                  </p>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { error } = state.Login;
  return { error };
};

export default withRouter(
  connect(mapStatetoProps, { loginUser, apiError })(Login)
);
