import React, { useEffect } from 'react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

//i18n
import { withTranslation } from 'react-i18next';

const SidebarContent = (props) => {
  const decodedToken = JSON.parse(localStorage.getItem('authUser'));

  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    var pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu('#side-menu');
      var matchingMenuItem = null;
      var ul = document.getElementById('side-menu');
      var items = ul.getElementsByTagName('a');
      for (var i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  function activateParentDropdown(item) {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  }

  return (
    <React.Fragment>
      <div id="sidebar-menu">
        <ul className="metismenu list-unstyled" id="side-menu">
          <li className="menu-title">{props.t('Menu')} </li>
          {decodedToken.userType === 'bbUser' ? (
            <>
              {(decodedToken.userRole === 'Super Admin' ||
                decodedToken.userRole === 'Admin') && (
                <li>
                  <Link to="/bbUser/users" className="waves-effect">
                    <i className="bx bx-user"></i>
                    <span>{props.t('BB Users')}</span>
                  </Link>
                </li>
              )}
              <li>
                <Link to="/bbUser/agents" className="waves-effect">
                  <i className="bx bx-user"></i>
                  <span>{props.t('Agents')}</span>
                </Link>
              </li>
              <li>
                <Link to="/bbUser/builders" className="waves-effect">
                  <i className="bx bx-user"></i>
                  <span>{props.t('Builders')}</span>
                </Link>
              </li>
              <li>
                <Link to="/bbUser/marketingAgencies" className="waves-effect">
                  <i className="fas fa-users" style={{ fontSize: '16px' }}></i>
                  <span>{props.t('Marketing Agencies')}</span>
                </Link>
              </li>
              <li>
                <Link to="/bbUser/bbUserContacts" className="waves-effect">
                  <i className="fa fa-phone"></i>
                  <span>{props.t('Contacts')}</span>
                </Link>
              </li>
              <li>
                <Link to="/bbUser/projects" className="waves-effect">
                  <i className="bx bx-briefcase-alt-2"></i>
                  <span>{props.t('Projects')}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t('KYC')}</li>
              <li>
                <Link to="/bbUser/kycDetails" className="waves-effect">
                  <i className="far fa-address-card"></i>
                  <span>{props.t('KYC Details')}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t('Master')} </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-map"></i>
                  <span>{props.t('Locations')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/bbUser/master/country">
                      {props.t('Country')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bbUser/master/state">{props.t('State')}</Link>
                  </li>
                  <li>
                    <Link to="/bbUser/master/city">{props.t('City')}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/bbUser/master/languages" className="waves-effect">
                  <i className="fa fa-language"></i>
                  <span>{props.t('Languages')}</span>
                </Link>
              </li>
              <li>
                <Link to="/#" className="has-arrow waves-effect">
                  <i className="bx bx-map"></i>
                  <span>{props.t('Amenities & Specs')}</span>
                </Link>
                <ul className="sub-menu" aria-expanded="false">
                  <li>
                    <Link to="/bbUser/master/amenity">
                      {props.t('Amenities')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/bbUser/master/specification">
                      {props.t('Specifications')}
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          ) : null}
          {decodedToken.userType === 'agent' ? (
            <>
              <li>
                <Link to="/agent/dashboard" className="waves-effect">
                  <i className="fas fa-tachometer-alt"></i>
                  <span>{props.t('Dashboard')}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t('Verified Builders')}</li>
              <li>
                <Link to="/agent/builders" className="waves-effect">
                  <i className="bx bx-user"></i>
                  <span>{props.t('Sent Request to Builders')}</span>
                </Link>
              </li>
              <li>
                <Link to="/agent/contacts" className="waves-effect">
                  <i className="fa fa-phone"></i>
                  <span>{props.t('Contacts')}</span>
                </Link>
              </li>
              <li>
                {/* <Link to="/agent/duplicateContacts" className="waves-effect">
                                <i className="far fa-copy"></i>
                                <span>{props.t('Duplicate Contacts')}</span>
                            </Link> */}
              </li>
              <li>
                <Link to="/agent/projects" className="waves-effect">
                  <i className="bx bx-briefcase-alt-2"></i>
                  <span>{props.t('Projects')}</span>
                </Link>
              </li>
              <li>
                <Link to="/agent/leads" className="waves-effect">
                  <i className="fas fa-id-card"></i>
                  <span>{props.t('Leads')}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t('Jobs')}</li>
              <li>
                <Link to="/agent/jobs" className="waves-effect">
                  <i className="fas fa-briefcase"></i>
                  <span>{props.t('Apply Jobs')}</span>
                </Link>
                <Link to="/agent/appliedJobs" className="waves-effect">
                  <i className="far fa-address-card"></i>
                  <span>{props.t('Applied Jobs')}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t('YOUR KYC')}</li>
              <li>
                <Link to="/agent/documentsUpload" className="waves-effect">
                  <i className="fas fa-file-upload"></i>
                  <span>{props.t('Documents Upload')}</span>
                </Link>
              </li>
            </>
          ) : null}
          {decodedToken.userType === 'builder' ? (
            <>
              <li>
                <Link to="/builder/dashboard" className="waves-effect">
                  <i className="bx bx-user"></i>
                  <span>{props.t('Dashboard')}</span>
                </Link>
              </li>
              {(decodedToken.userRole === 'Super Admin' ||
                decodedToken.userRole === 'Admin') && (
                <>
                  <li className="menu-title">{props.t('USERS')}</li>
                  <li>
                    <Link to="/builder/users" className="waves-effect">
                      <i className="bx bx-user"></i>
                      <span>{props.t('Employees')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/builder/agents" className="waves-effect">
                      <i className="bx bx-user"></i>
                      <span>{props.t('Agents')}</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/builder/agentRequests" className="waves-effect">
                      <i className="bx bx-user"></i>
                      <span>{props.t('Agent Requests')}</span>
                    </Link>
                  </li>
                </>
              )}
              <li className="menu-title">{props.t('Contacts')}</li>
              <li>
                <Link to="/builder/contacts" className="waves-effect">
                  <i className="fa fa-phone"></i>
                  <span>{props.t('Contacts')}</span>
                </Link>
              </li>
              <li>
                <Link to="/builder/duplicateContacts" className="waves-effect">
                  <i className="far fa-copy"></i>
                  <span>{props.t('Duplicate Contacts')}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t('Project')}</li>
              <li>
                <Link to="/builder/projects" className="waves-effect">
                  <i className="bx bx-briefcase-alt-2"></i>
                  <span>{props.t('Projects')}</span>
                </Link>
              </li>
              <li>
                <Link to="/builder/leads" className="waves-effect">
                  <i className="fas fa-id-card"></i>
                  <span>{props.t('Leads')}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t('Jobs')}</li>
              <li>
                <Link to="/builder/project/jobs" className="waves-effect">
                  <i className="fas fa-briefcase"></i>
                  <span>{props.t('Jobs')}</span>
                </Link>
              </li>
              <li>
                <Link to="/builder/jobApplications" className="waves-effect">
                  <i className="far fa-address-card"></i>
                  <span>{props.t('Job Applications')}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t('YOUR KYC')}</li>
              <li>
                <Link to="/agent/documentsUpload" className="waves-effect">
                  <i className="fas fa-file-upload"></i>
                  <span>{props.t('Documents Upload')}</span>
                </Link>
              </li>
            </>
          ) : null}
          {decodedToken.userType === 'marketingAgency' ? (
            <>
              <li>
                <Link to="/marketingAgency/dashboard" className="waves-effect">
                  <i className="fas fa-tachometer-alt"></i>
                  <span>{props.t('Dashboard')}</span>
                </Link>
              </li>
              {(decodedToken.userRole === 'Super Admin' ||
                decodedToken.userRole === 'Admin') && (
                <li>
                  <Link to="/marketingAgency/users" className="waves-effect">
                    <i className="bx bx-user"></i>
                    <span>{props.t('Users')}</span>
                  </Link>
                </li>
              )}
              <li className="menu-title">{props.t('Contacts')}</li>
              <li>
                <Link to="/marketingAgency/contacts" className="waves-effect">
                  <i className="fa fa-phone"></i>
                  <span>{props.t('Contacts')}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/marketingAgency/duplicateContacts"
                  className="waves-effect"
                >
                  <i className="far fa-copy"></i>
                  <span>{props.t('Duplicate Contacts')}</span>
                </Link>
              </li>
              <li>
                <Link to="/marketingAgency/builders" className="waves-effect">
                  <i className="bx bx-user"></i>
                  <span>{props.t('Builders')}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t('Projects')}</li>
              <li>
                <Link to="/marketingAgency/projects" className="waves-effect">
                  <i className="bx bx-briefcase-alt-2"></i>
                  <span>{props.t('Projects')}</span>
                </Link>
              </li>
              <li>
                <Link to="/marketingAgency/leads" className="waves-effect">
                  <i className="fas fa-id-card"></i>
                  <span>{props.t('Leads')}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t('Jobs')}</li>
              <li>
                <Link
                  to="/marketingAgency/project/jobs"
                  className="waves-effect"
                >
                  <i className="fas fa-briefcase"></i>
                  <span>{props.t('Jobs')}</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/marketingAgency/jobApplications"
                  className="waves-effect"
                >
                  <i className="far fa-address-card"></i>
                  <span>{props.t('Job Applications')}</span>
                </Link>
              </li>
              <li className="menu-title">{props.t('YOUR KYC')}</li>
              <li>
                <Link to="/agent/documentsUpload" className="waves-effect">
                  <i className="fas fa-file-upload"></i>
                  <span>{props.t('Documents Upload')}</span>
                </Link>
              </li>
            </>
          ) : null}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default withRouter(withTranslation()(SidebarContent));
