import React, { Suspense, useEffect } from "react";

import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { connect, useDispatch } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
import "./App.css";

import Loader from "./components/Common/Loader";
import { getCountries, getUserRoles } from "./store/actions";
import app from "./initFirebase";

console.log("firebase app", app);

const App = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      dispatch(getUserRoles());
      dispatch(getCountries());
    }
  }, []);

  const Layout = VerticalLayout;

  const NonAuthmiddleware = ({ component: Component, layout: Layout }) => (
    <Route
      render={(props) => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );

  return (
    <React.Fragment>
      <Loader />
      <Suspense fallback={<h1>Loading...</h1>}>
        <Router>
          <Switch>
            {authRoutes.map((route, idx) => (
              <NonAuthmiddleware
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Authmiddleware
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
              />
            ))}
          </Switch>
        </Router>
      </Suspense>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
