/* eslint-disable react-hooks/rules-of-hooks */
import { createBrowserHistory } from 'history';
import store from '../store';
import {
  ToastInfo,
  ToastError,
  ToastSuccess,
  ToastWarning,
} from '../components/Common/Toast';
import { setLoaderData } from '../store/loader/loaderStore';
import { logoutUser } from '../store/auth/login/actions';

const fetchApi = (api, queryBody, requestType) => {
  const history = createBrowserHistory();
  let isFormData = false;
  setLoaderData(true);
  if (queryBody) {
    if (queryBody instanceof FormData) {
      isFormData = true;
    }
  }
  const requestOptions = {
    method: requestType
      ? requestType.toUpperCase()
      : queryBody
      ? 'POST'
      : 'GET',
    headers: {
      Authorization: localStorage.getItem('accessToken'),
    },

    body: isFormData ? queryBody : JSON.stringify(queryBody),
  };

  if (!isFormData) {
    requestOptions.headers['Content-Type'] = 'application/json';
  }

  const isJSON = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  return fetch(api, requestOptions)
    .then((response) =>
      response.json().then((response) => {
        setLoaderData(false);
        if (
          (response.status &&
            response.status !== 200 &&
            response.status !== 201) ||
          response.errors
        ) {
          throw new Error(JSON.stringify(response));
        } else {
          if (response.message) {
            if (response.type === 'warning') {
              ToastWarning(response.message);
            } else {
              ToastSuccess(response.message);
            }
          }
          return Promise.resolve(response);
        }
      })
    )
    .catch((error) => {
      setLoaderData(false);
      if (error.message === 'Failed to fetch') {
        ToastError('Something went wrong');
      } else {
        let errMsg =
          typeof error.message === 'string'
            ? error.message
            : JSON.parse(error.message);
        errMsg = errMsg.message
          ? errMsg.message
          : typeof errMsg === 'string'
          ? isJSON(errMsg)
            ? JSON.parse(errMsg)
            : errMsg
          : errMsg;

        switch (+errMsg.status) {
          case 422: {
            for (let index = 0; index < errMsg.errors.length; index++) {
              const element = errMsg.errors[index];
              ToastInfo(element.msg);
            }
            break;
          }
          case 400: {
            ToastWarning(errMsg.message);
            break;
          }
          case 401: {
            store.dispatch(logoutUser(history));
            ToastError(errMsg.message);
            break;
          }
          default: {
            ToastError('Sorry something went wrong');
          }
        }
      }
    });
};

const ApiHandling = {
  fetchApi,
};

export default ApiHandling;
