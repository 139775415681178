import * as moment from 'moment';

export const getDDMMYYFormat = (dateParam) => {
  return moment(dateParam).format('MMM DD YYYY, hh:mm:ss');
};

export const getDDMMYYFormatOnly = (dateParam) => {
  return moment(dateParam).format('MMM DD YYYY, hh:mm A');
};

export const getDDMMYYFormat2 = (dateParam) => {
  return moment(dateParam).format('DD MMM, YYYY');
};

export const timeAgo = (date) => {
  const currentDate = new Date();
  const notificationDate = new Date(date);

  const timeDifference = currentDate - notificationDate;
  const seconds = Math.floor(timeDifference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days} day${days === 1 ? '' : 's'} ago`;
  } else if (hours > 0) {
    return `${hours} hour${hours === 1 ? '' : 's'} ago`;
  } else if (minutes > 0) {
    return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
  } else {
    return 'Just now';
  }
};
