import {
  GET_COUNTRIES,
  GET_USER_ROLES,
  SET_COUNTRIES,
  SET_USER_ROLES,
  GET_INACTIVE_LEADS,
  SET_INACTIVE_LEADS,
  CLEAR_INACTIVE_LEADS,
} from './actionTypes';

export const getUserRoles = () => {
  return {
    type: GET_USER_ROLES,
  };
};
export const setUserRoles = (payload) => {
  return {
    type: SET_USER_ROLES,
    payload,
  };
};

export const getCountries = () => {
  return {
    type: GET_COUNTRIES,
  };
};

export const setCountries = (payload) => {
  return {
    type: SET_COUNTRIES,
    payload,
  };
};

export const getIncativeLeads = () => {
  return {
    type: GET_INACTIVE_LEADS,
  };
};

export const setInactiveLeads = (payload) => {
  return {
    type: SET_INACTIVE_LEADS,
    payload,
  };
};

export const clearInactiveLeads = () => {
  return { type: CLEAR_INACTIVE_LEADS };
};
