import React, { useEffect } from 'react';
import { loader$ } from '../../store/loader/loaderStore';

const Loader = () => {
  useEffect(() => {
    loader$.subscribe(res => {
      if (document.getElementById("preloader")) {
        if (res) {
          document.getElementById("preloader").style.display = "block";
          document.getElementById("status").style.display = "block";
        } else {
          document.getElementById("preloader").style.display = "none";
          document.getElementById("status").style.display = "none";
        }
      }
    });
  });
  return (
    <React.Fragment>
    </React.Fragment>
  );
}

export default Loader;